
import { focus, willChange } from '@storefront-ui/vue/src/utilities/directives'
import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api'
import BaseButton from '~/components/BaseButton.vue'
import SkeletonLoader from '~/components/SkeletonLoader/index.vue'

export default defineComponent({
  name: 'BaseInput',
  components: {
    BaseButton,
    SkeletonLoader
  },
  directives: {
    focus,
    willChange
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    icon: {
      type: String,
      default: undefined
    },
    valid: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false,
      description: 'Native input required attribute'
    },
    disabled: {
      type: Boolean,
      default: false,
      description: 'Native input disabled attribute'
    },
    hasShowPassword: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { listeners: $listeners, emit }) {
    const isPasswordVisible = ref<boolean>(false)
    const isNumberTypeSafari = ref<boolean>(false)
    const inputType = ref<string>('')

    const isPassword = computed(() => props.type === 'password' && props.hasShowPassword)
    const nameWithoutWhitespace = computed(() => props.name.replace(/\s/g, ''))
    const listeners = computed(() => {
      return {
        ...$listeners,
        input: event => emit('input', event.target.value)
      }
    })

    const switchVisibilityPassword = () => {
      if (props.type !== 'password') return
      isPasswordVisible.value = !isPasswordVisible.value
      inputType.value = isPasswordVisible.value ? 'text' : 'password'
    }

    watch(() => props.type, (type) => {
      let tempType: string = type
      // Safari has bug for number input
      if (typeof window !== 'undefined' || typeof document !== 'undefined') {
        const ua = navigator.userAgent.toLocaleLowerCase()
        if (
          ua.includes('safari') &&
          !ua.includes('chrome') &&
          type === 'number'
        ) {
          isNumberTypeSafari.value = true
          tempType = 'text'
        }
      }
      inputType.value = tempType
    }, { immediate: true })

    watch(() => props.value, (value) => {
      if (!isNumberTypeSafari.value) return
      if (isNaN(value as number)) {
        emit('input')
      }
    }, { immediate: true })

    return {
      isPasswordVisible,
      isNumberTypeSafari,
      inputType,
      listeners,
      isPassword,
      nameWithoutWhitespace,
      switchVisibilityPassword
    }
  }
})
