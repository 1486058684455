var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"will-change",rawName:"v-will-change",value:('font-size'),expression:"'font-size'"}],staticClass:"base-input",class:{
    'has-text': !!_vm.value,
    'invalid': !_vm.valid,
  },attrs:{"data-testid":_vm.nameWithoutWhitespace}},[_c('label',{staticClass:"base-input__label will-change",class:{ 'display-none': !_vm.label },attrs:{"for":_vm.nameWithoutWhitespace}},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label))]},null,{ label: _vm.label })],2),_vm._v(" "),_c('div',{staticClass:"base-input__field"},[_c('div',{staticClass:"base-input__wrapper"},[(_vm.isLoading)?_c('skeleton-loader',{staticClass:"base-input__loader",attrs:{"height":"44px","radius":"40px","margin":"0"}}):_c('input',_vm._g(_vm._b({directives:[{name:"focus",rawName:"v-focus"}],class:{ 'base-input--is-password': _vm.isPassword },attrs:{"id":_vm.nameWithoutWhitespace,"required":_vm.required,"disabled":_vm.disabled,"name":_vm.name,"type":_vm.inputType,"aria-invalid":!_vm.valid,"aria-required":_vm.required,"aria-describedby":_vm.errorMessage ? `${_vm.nameWithoutWhitespace}-error` : null},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners)),_vm._v(" "),_c('span',{staticClass:"base-input__bar"}),_vm._v(" "),_vm._t("icon",function(){return [(_vm.icon)?_c('base-button',{staticClass:"base-input__button sf-button--pure",on:{"click":function($event){return _vm.$emit('click:icon')}}},[_c('i',{class:[`icon-${_vm.icon}`,'icon_medium', 'base-input__icon'],attrs:{"icon":_vm.icon}})]):(_vm.hasShowPassword)?_c('base-button',{staticClass:"base-input__password-button",class:{ 'display-none': !_vm.isPassword },attrs:{"type":"button","aria-label":'switch-visibility-password',"aria-pressed":_vm.isPasswordVisible.toString()},on:{"click":_vm.switchVisibilityPassword}},[(_vm.icon)?_c('i',{class:[`icon-${_vm.icon}`, 'icon_medium', 'base-input__password-icon',{
              hidden: !_vm.isPasswordVisible,
            }]}):_vm._e()]):_vm._e()]},null,{
          icon: _vm.icon,
          isPasswordVisible: _vm.isPasswordVisible,
          switchVisibilityPassword: _vm.switchVisibilityPassword,
        })],2),_vm._v(" "),_c('div',{staticClass:"base-input__error-message"},[_c('transition',{attrs:{"name":"fade"}},[_vm._t("error-message",function(){return [_c('div',{class:{ 'display-none': _vm.valid },attrs:{"id":`${_vm.nameWithoutWhitespace}-error`,"aria-live":"assertive"}},[_vm._v("\n            "+_vm._s(_vm.errorMessage)+"\n          ")])]},null,{ errorMessage: _vm.errorMessage })],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }