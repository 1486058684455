<template>
  <section class="modal" :class="[staticClass, className]">
    <overlay :visible="visible" />
    <transition :name="transitionModal">
      <div
        v-if="visible"
        v-focus-trap
        v-click-outside="checkPersistence"
        class="modal__container"
      >
        <slot name="modal-bar">
          <div class="modal__bar">
            <div class="modal__bar-heading">
              <i
                v-if="titleIcon"
                :class="`icon-${titleIcon} modal__bar-icon`"
              />
              <h4 class="modal__bar-title">
                {{ title }}
              </h4>
            </div>
            <base-button class="modal__bar-btn" @click="close">
              <i class="icon-close" />
            </base-button>
          </div>
        </slot>
        <div ref="content" class="modal__content">
          <slot />
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { isClient } from '@storefront-ui/vue/src/utilities/helpers'
import { focusTrap } from '@storefront-ui/vue/src/utilities/directives'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive'

import Overlay from '~/components/Overlay.vue'
import BaseButton from '~/components/BaseButton.vue'

export default {
  name: 'BaseModal',
  directives: { focusTrap, clickOutside },
  components: {
    BaseButton,
    Overlay
  },
  model: {
    prop: 'visible',
    event: 'close'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    titleIcon: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    cross: {
      type: Boolean,
      default: true
    },
    overlay: {
      type: Boolean,
      default: true
    },
    persistent: {
      type: Boolean,
      default: false
    },
    transitionModal: {
      type: String,
      default: 'fade-in-down'
    }
  },
  data () {
    return {
      staticClass: null,
      className: null
    }
  },
  watch: {
    visible: {
      handler: function (value) {
        if (!isClient) return
        if (value) {
          this.$nextTick(() => {
            disableBodyScroll(this.$refs.content)
          })
          document.addEventListener('keydown', this.keydownHandler)
        } else {
          clearAllBodyScrollLocks()
          document.removeEventListener('keydown', this.keydownHandler)
        }
      },
      immediate: true
    }
  },
  beforeMount () {
    clearAllBodyScrollLocks()
  },
  methods: {
    close () {
      this.$emit('close', false)
    },
    checkPersistence () {
      if (!this.persistent) {
        this.close()
      }
    },
    keydownHandler (e) {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.close()
      }
    },
    classHandler () {
      if (this.staticClass !== this.$vnode.data.staticClass) {
        this.staticClass = this.$vnode.data.staticClass
      }
      if (this.className !== this.$vnode.data.class) {
        this.className = this.$vnode.data.class
      }
    }
  }
}
</script>

<style lang="scss">
.modal {
  &__container {
    position: fixed;
    top: var(--modal-top, 0);
    right: var(--modal-right, 0);
    bottom: var(--modal-bottom, 0);
    left: var(--modal-left, 0);
    z-index: var(--modal-index, 100);
    transform: var(--modal-transform);
    display: flex;
    flex-direction: var(--modal-flex-direction, column);
    align-content: space-between;
    box-sizing: border-box;
    width: var(--modal-width);
    height: var(--modal-height);
    border: var(--modal-border);
    border-radius: 10px;
    max-height: var(--modal-max-height);
    background-color: var(--modal-background, var(--c-white));

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  @include for-tablets-up {
    --modal-width: 750px;
    --modal-top: 50%;
    --modal-left: 50%;
    --modal-bottom: none;
    --modal-right: none;
    --modal-transform: translate3d(-50%, -50%, 0);
    --modal-height: auto;
    --modal-max-height: 90%;
    --modal-content-height: 100%;
  }

  &__bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--modal-bar-height, 65px);
    padding: var(--modal-bar-padding, 20px 24px);
    background-color: var(--modal-bar-background-color);
    border-bottom: var(--modal-bar-border-bottom);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &-heading {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &-icon {
      margin: var(--modal-bar-icon-margin, 0 10px 0 0);
    }

    &-title {
      font-weight: 500;
    }

    &-btn {
      --icon-color: var(--c-dark);
      --icon-font-size: 20px;
      --button-padding: 0;

      &:hover {
        --icon-color: var(--c-black);
      }
    }
  }

  &__content {
    overflow-y: auto;
    padding: var(--modal-content-padding, 24px);
    height: var(--modal-content-height);

    @include for-mobile {
      padding: var(--modal-content-padding, 16px);
    }
  }
}
</style>
