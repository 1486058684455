
import { SfCircleIcon } from '@storefront-ui/vue'
import { ref, defineComponent } from '@nuxtjs/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ProviderInstance } from 'vee-validate/dist/types/types'
import { SubscriptionStatusesEnum } from '@vue-storefront/magento-api'
import { useManagedNewsletter } from '../composables/useManagedNewsletter'
import { useUiNotification, useImage } from '~/composables'
import { useI18n } from '~/helpers/hooks/usei18n'
import { safeComputed } from '~/modules/helpers'
import BaseInput from '~/components/atoms/BaseInput.vue'
import BaseModal from '~/components/molecules/BaseModal.vue'
import BaseButton from '~/components/BaseButton.vue'

export default defineComponent({
  name: 'ManagedNewsletterModal',
  components: {
    SfCircleIcon,
    BaseModal,
    BaseInput,
    BaseButton,
    ValidationProvider,
    ValidationObserver
  },
  setup () {
    const {
      config, isOpenModal, isLoading, closeModal, updateSubscription
    } = useManagedNewsletter()
    const i18n = useI18n()
    const { getMagentoImage } = useImage()
    const { send: sendNotification } = useUiNotification()

    const validationInstance = ref<ProviderInstance>(null)
    const form = ref<{name: string, email: string}>({
      name: '',
      email: ''
    })
    const isSubscriptionSuccess = ref<boolean>(false)
    const heading = safeComputed<string>(() => config.value?.heading ?? '')
    const subheading = safeComputed<string>(() => config.value?.subheading ?? '')
    const description = safeComputed<string>(() => config.value?.description ?? '')
    const imageUrl = safeComputed<string>(() => getMagentoImage(config.value?.headingImage?.url ?? ''))

    async function submitForm (): Promise<void> {
      if (form.value.name && form.value.email) {
        // TODO: The use of 'form.value.name' data should be implemented later
        const status: SubscriptionStatusesEnum = await updateSubscription({ email: form.value.email })
        if (status === SubscriptionStatusesEnum.Subscribed) {
          form.value.email = ''
          isSubscriptionSuccess.value = true
          closeModal()
          sendNotification({
            id: Symbol('subscribed-to-newsletter'),
            type: 'success',
            icon: 'newsletter-success',
            title: i18n.t('Newsletter subscription successful'),
            persist: false,
            timeToLive: 5000
          })
        } else {
          validationInstance.value.setErrors(['This email address already has been used'])
          sendNotification({
            id: Symbol('newsletter-subscription-failed'),
            type: 'danger',
            icon: 'newsletter-warning',
            title: i18n.t('You are already subscribed to newsletter with this e-mail address'),
            persist: false,
            timeToLive: 5000
          })
        }
      }
    }

    return {
      validationInstance,
      form,
      heading,
      subheading,
      description,
      imageUrl,
      isLoading,
      isOpenModal,
      submitForm,
      closeModal
    }
  }
})
